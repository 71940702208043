import { useQuery, useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import React, { ComponentType } from 'react'
import { useCountry } from '@scentregroup/shared/country'
import { useLocalUser } from '@scentregroup/shared/hooks/use-local-user'
import { ITermsConditionsVersion } from '@scentregroup/shared/types/terms'

import { AcceptTermsAndConditions } from '../../graphql/mutations'
import { GetTermsConditionsVersion } from '../../graphql/queries'
import { LOGIN, LOGOUT, incBaseAccount } from '../../lib/paths'
import { useAuthRedirectManager } from '../../lib/use-auth-redirect'
import useUser from '../../lib/use-user'
import SpinnerDelayed from '../spinner-delayed'
import { shouldAutoAcceptTerms } from './helpers'
import { checkForRedirect } from './redirect'

const withRequiresSession =
  <P,>(
    Component: ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<P>> =>
  props => {
    const router = useRouter()
    const country = useCountry()
    const { saveCurrentPath } = useAuthRedirectManager()

    const { user, error: userError, loading: userLoading } = useUser()
    const userLocal = useLocalUser()

    const { data: termsData, loading: termsLoading } =
      useQuery<ITermsConditionsVersion>(GetTermsConditionsVersion, {
        variables: { country },
        errorPolicy: 'ignore',
      })

    const [acceptTerms, { called: acceptTermsCalled }] = useMutation(
      AcceptTermsAndConditions
    )

    const termsVersionCurrent =
      termsData?.cfNationalHomepageCollection?.items?.[0]
        .termsAndConditionsVersion
    const termsAutoAccept = shouldAutoAcceptTerms(user)

    // Wait until user has loaded
    if (userLoading) {
      return <SpinnerDelayed />
    }

    // Auto accept terms for web sign-ups
    if (termsAutoAccept && termsVersionCurrent && !acceptTermsCalled) {
      acceptTerms({
        variables: { input: { version: termsVersionCurrent.toString() } },
      })
    }

    // Check if the user's account requires a redirect e.g. requires onboarding
    const { redirectPath, isExternal, isLogoutRequired } = checkForRedirect({
      user,
      userError,
      userLocal,
      termsAutoAccept,
      termsLoading,
      termsVersionCurrent,
    })

    // Only redirect if we're not on the page we're redirecting to
    if (redirectPath !== undefined && router.pathname !== redirectPath) {
      if (redirectPath === `/${LOGIN}`) {
        saveCurrentPath()
      }

      if (isLogoutRequired) {
        router.push(`/${LOGOUT}?path=${incBaseAccount(redirectPath)}`)
      } else if (isExternal) {
        window.location.assign(`${window.location.origin}${redirectPath}`)
      } else {
        router.push(redirectPath)
      }

      return <SpinnerDelayed />
    }

    return <Component {...props} />
  }

export default withRequiresSession
