import { ApolloError } from '@apollo/client'
import { LocalUser } from '@scentregroup/shared/hooks/use-local-user'

import {
  LOGIN,
  CHECK_EMAIL,
  ONBOARDING_YOUR_DETAILS,
  ONBOARDING_TERMS_CONDITIONS,
  // MIGRATE,
} from '../../lib/paths'
import { IUser } from '../../types/user'

type RedirectTo = {
  redirectPath: string | undefined
  isExternal?: boolean
  isLogoutRequired?: boolean
}

export function checkForRedirect({
  user,
  userLocal,
  userError,
  termsAutoAccept,
  termsLoading,
  termsVersionCurrent,
}: {
  user: Pick<IUser, 'auth0' | 'onboarding' | 'westfieldPlus'> | undefined
  userLocal:
    | Pick<LocalUser, 'email' | 'emailVerified' | 'emailPending'>
    | undefined
  userError: ApolloError | undefined
  termsAutoAccept: boolean
  termsLoading: boolean
  termsVersionCurrent: number | undefined
}): RedirectTo {
  // Disabling this for now because it is
  // causing more problems than it is solving.
  // if (requiresAccountMigration(user)) {
  //   return {
  //     redirectPath: `/${MIGRATE}`,
  //     isLogoutRequired: true,
  //   }
  // }

  if (requiresEmailVerification(userLocal)) {
    return { redirectPath: `/${CHECK_EMAIL}` }
  }

  if (requiresLogin({ userError, userLocal })) {
    return { redirectPath: `/${LOGIN}` }
  }

  if (requiresOnboarding(user)) {
    return { redirectPath: `/${ONBOARDING_YOUR_DETAILS}` }
  }

  if (
    requiresTermsAcceptance({
      user,
      termsAutoAccept,
      termsLoading,
      termsVersionCurrent,
    })
  ) {
    return { redirectPath: `/${ONBOARDING_TERMS_CONDITIONS}` }
  }

  return { redirectPath: undefined }
}

// Accounts requiring migration are ones that were created before we moved to auth0 for authentication.
// These accounts have a username/password ('PASSWORD' connection), however have never gone through
// a passwordless flow. This causes complications with features like change email. Therefore if a user
// has one of these accounts we will log them out and require them to sign up.
export function requiresAccountMigration(
  user: Pick<IUser, 'auth0'> | undefined
): boolean {
  if (!user) {
    return false
  }

  const defaultConnection = user.auth0?.defaultConnection ?? ''

  return Boolean(defaultConnection === 'PASSWORD')
}

export function requiresEmailVerification(
  userLocal:
    | Pick<LocalUser, 'email' | 'emailVerified' | 'emailPending'>
    | undefined
): boolean {
  if (!userLocal) {
    return false
  }

  const email = userLocal?.email ?? ''
  const emailVerified = userLocal?.emailVerified ?? false
  const pendingEmail = userLocal?.emailPending ?? ''

  return Boolean(
    pendingEmail &&
      (email !== pendingEmail || (email === pendingEmail && !emailVerified))
  )
}

function requiresLogin({
  userError,
  userLocal,
}: {
  userError: ApolloError | undefined
  userLocal: Pick<LocalUser, 'emailVerified'> | undefined
}): boolean {
  if (!userLocal) {
    return true
  }

  if (userError && userError.message === 'Unauthenticated') {
    return true
  }

  const emailVerified = userLocal?.emailVerified ?? false
  return !emailVerified
}

function requiresOnboarding(
  user: Pick<IUser, 'onboarding'> | undefined
): boolean {
  const web = user?.onboarding?.web
  return Boolean(web && web.required.length > 0)
}

export function requiresTermsAcceptance({
  user,
  termsAutoAccept,
  termsLoading,
  termsVersionCurrent,
}: {
  user: Pick<IUser, 'westfieldPlus'> | undefined
  termsAutoAccept?: boolean
  termsLoading?: boolean
  termsVersionCurrent: number | undefined
}): boolean {
  if (termsAutoAccept || termsLoading || !user) {
    return false
  }

  const termsVersionAccepted = user.westfieldPlus?.termsAndConditionsVersion
  return (
    termsVersionCurrent !== undefined &&
    termsVersionCurrent?.toString() !== termsVersionAccepted?.toString()
  )
}
